import { Layout } from "antd";
import styled, { css } from "styled-components";

interface IGenericHeaderProps {
  $isBottomShadowed: boolean;
}

export const GenericHeader = styled(Layout.Header)<IGenericHeaderProps>`
  transition: 0.5s ease;
  z-index: 1;
  ${(props) =>
    props.$isBottomShadowed
      ? css`
          z-index: 1;
          transition: 0.5s ease;
          -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
          -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
          box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
        `
      : null}
`;
