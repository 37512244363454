import React, { useCallback } from "react";
import * as S from "./LogoWithTitle.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { Row } from "antd";

interface ILogoWithTitleProps {
  title: string;
  logo: string;
}

const LogoWithTitle: React.FC<ILogoWithTitleProps> = ({ title, logo }) => {
  const navigateToSection = useCallback((section: string) => {
    document.getElementById(section)?.scrollIntoView();
  }, []);

  return (
    <React.Fragment>
      <S.Container onClick={() => navigateToSection("home")}>
        <S.LogoImageContainer>
          <img width="100%" height="100%" src={logo} alt="System Logo" />
        </S.LogoImageContainer>
        <CommonStyles.BrandTitle>{title}</CommonStyles.BrandTitle>
      </S.Container>
    </React.Fragment>
  );
};

export default LogoWithTitle;
