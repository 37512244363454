import styled, { css } from "styled-components";
import { Row, Typography } from "antd";
import { FONT_SIZE, media } from "@common/constants";

interface ITextProps {
  $isLight?: boolean;
  $isExtraLight?: boolean;
}

export const WrapperRow = styled(Row)`
  width: 100%;
  padding: 1rem 1.125rem;
  height: auto;

  @media only screen and ${media.lg} {
    padding: 1rem 5rem;
  }

  @media only screen and ${media.xl} {
    padding: 1rem 10rem;
  }
`;

export const FullyCenteredRow = styled(Row)`
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
`;

export const FullyCenteredRowWithoutMargin = styled(Row)`
  width: 100%;
  justify-content: center;
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 0;
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const Subtitle = styled(Typography.Text)`
  margin: 0;
  font-weight: 600;
`;

export const Text = styled(Typography.Text)`
  opacity: 0.6;
  font-weight: 600;
`;

export const InfoText = styled(Typography.Text)`
  opacity: 0.6;
  font-size: 12px;
  font-weight: 600;
`;

export const BrandTitle = styled(Typography.Title)`
  &.ant-typography {
    font-family: "Neuropolitical";
    margin: 0;
    font-size: ${FONT_SIZE.xxl};
    color: var(--primary-color);
  }
`;

export const Sn1Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 0 0 0.5rem 0;
    font-size: ${FONT_SIZE.x4l};

    color: var(--text-main-color);
  }
`;

export const Sn1Text = styled(Typography.Text)<ITextProps>`
  font-size: ${FONT_SIZE.lg};

  ${({ $isLight }) =>
    $isLight
      ? css`
          color: var(--text-light-color);
        `
      : ""}

  ${({ $isExtraLight }) =>
    $isExtraLight
      ? css`
          color: var(--text-extra-light-color);
        `
      : ""}
`;

export const Sn2Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 0 0 0.5rem 0;
    font-size: ${FONT_SIZE.xxl};

    color: var(--text-main-color);
  }
`;

export const Sn2Text = styled(Typography.Text)<ITextProps>`
  font-size: ${FONT_SIZE.md};

  ${({ $isLight }) =>
    $isLight
      ? css`
          color: var(--text-light-color);
        `
      : ""}

  ${({ $isExtraLight }) =>
    $isExtraLight
      ? css`
          color: var(--text-extra-light-color);
        `
      : ""}
`;

export const Sn3Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 0 0 0.5rem 0;
    font-size: ${FONT_SIZE.lg};

    color: var(--text-main-color);
  }
`;

export const Sn3Text = styled(Typography.Text)<ITextProps>`
  font-size: ${FONT_SIZE.xs};

  ${({ $isLight }) =>
    $isLight
      ? css`
          color: var(--text-light-color);
        `
      : ""}

  ${({ $isExtraLight }) =>
    $isExtraLight
      ? css`
          color: var(--text-extra-light-color);
        `
      : ""}
`;

export const Sn4Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 0 0 0.5rem 0;
    font-size: ${FONT_SIZE.sm};

    color: var(--text-main-color);
  }
`;

export const Sn4Text = styled(Typography.Text)<ITextProps>`
  font-size: ${FONT_SIZE.xxs};

  ${({ $isLight }) =>
    $isLight
      ? css`
          color: var(--text-light-color);
        `
      : ""}

  ${({ $isExtraLight }) =>
    $isExtraLight
      ? css`
          color: var(--text-extra-light-color);
        `
      : ""}
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: var(--white);
  margin-right: 20px;
  background-color: var(--icon-container-color);

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;
  }
`;
