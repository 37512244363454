import { Row, Typography } from "antd";
import styled from "styled-components";

export const CopyRightContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CopyRightText = styled(Typography.Text)`
  opacity: 0.5;
  font-size: 0.725rem;
  font-weight: 600;
`;
