import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@common/enums";
import { ApiResponseState, CountryInfoType } from "@common/types";
import { fetchUserLocationInfo } from "./thunks";
import { CountryInfo as CountryInfoDict } from "@app/common/CountryInfo";

const initialState: ApiResponseState<CountryInfoType> = {
  status: RequestStatus.IDLE,
  data: {} as CountryInfoType,
  error: null,
};

const userAgentSlice = createSlice({
  name: "userAgent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLocationInfo.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(fetchUserLocationInfo.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        const countryInformation = CountryInfoDict[action.payload.country_code];
        state.data = countryInformation
          ? countryInformation
          : ({} as CountryInfoType);
      })
      .addCase(fetchUserLocationInfo.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      });
  },
});

export default userAgentSlice.reducer;
