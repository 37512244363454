import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import axios from "axios";
import { ContactFormValues } from "@app/common/formTypes";
import { NotificationController } from "@app/controller/NotificationController/NotificationController";
import { BaseForm } from "@components/forms/BaseForm";
import * as S from "./ContactForm.style";
import { useTranslation } from "react-i18next";
import { useLanguage } from "@app/common/hooks/useLanguage";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const { language } = useLanguage();

  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);

  const CONTACT_INTEREST_OPTIONS = useMemo(
    () => [
      {
        title: t(
          "pages.main.sections.contact.formFields.options.interest.registration.title"
        ),
        value: t(
          "pages.main.sections.contact.formFields.options.interest.registration.value"
        ),
      },
      {
        title: t(
          "pages.main.sections.contact.formFields.options.interest.getInfoAboutCompany.title"
        ),
        value: t(
          "pages.main.sections.contact.formFields.options.interest.getInfoAboutCompany.value"
        ),
      },
      {
        title: t(
          "pages.main.sections.contact.formFields.options.interest.getInfoAboutProduct.title"
        ),
        value: t(
          "pages.main.sections.contact.formFields.options.interest.getInfoAboutProduct.value"
        ),
      },
      {
        title: t(
          "pages.main.sections.contact.formFields.options.interest.other.title"
        ),
        value: t(
          "pages.main.sections.contact.formFields.options.interest.other.value"
        ),
      },
    ],
    [language, t]
  );

  const REQUIRE_RULE = useMemo(() => {
    return { required: false, message: "This field is required." };
  }, []);

  const EMAIL_RULE = useMemo(() => {
    return {
      required: false,
      type: "email",
      message: "Email is invalid.",
    };
  }, []);

  const onSubmit = useCallback((formValues: ContactFormValues) => {
    form.resetFields();
    setIsMessageLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_PATH}/contact/`, formValues)
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.contactFormSubmit"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.contactFormSubmit"),
        });
      })
      .finally(() => setIsMessageLoading(false));
  }, []);

  return (
    <S.ContactFormContainer>
      <BaseForm
        onFinish={(values: ContactFormValues) => onSubmit(values)}
        form={form}
        layout="vertical"
        name="contactForm"
        style={{ width: "100%" }}
      >
        <Row wrap>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BaseForm.Item
              name="name"
              label={t("common.fullName")}
              rules={[REQUIRE_RULE]}
            >
              <Input />
            </BaseForm.Item>
          </Col>
        </Row>
        <Row wrap>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BaseForm.Item
              name="email"
              label={t("common.email")}
              rules={[REQUIRE_RULE, EMAIL_RULE]}
            >
              <Input />
            </BaseForm.Item>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <BaseForm.Item
              name="company"
              label={t("pages.main.sections.contact.formFields.company")}
              rules={[REQUIRE_RULE]}
            >
              <Input />
            </BaseForm.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <BaseForm.Item
              name="hear"
              label={t("pages.main.sections.contact.formFields.hear")}
              rules={[REQUIRE_RULE]}
            >
              <Input />
            </BaseForm.Item>
          </Col>
        </Row>
        <Row wrap>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BaseForm.Item
              name="interested"
              label={t("pages.main.sections.contact.formFields.interest")}
              rules={[REQUIRE_RULE]}
            >
              <Select
                placeholder={t(
                  "pages.main.sections.contact.formFields.interestPlaceholder"
                )}
              >
                {CONTACT_INTEREST_OPTIONS.map((opt) => (
                  <Select.Option key={opt.title} value={opt.value}>
                    {opt.title}
                  </Select.Option>
                ))}
              </Select>
            </BaseForm.Item>
          </Col>
        </Row>
        <Row wrap>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BaseForm.Item
              name="message"
              label={t("pages.main.sections.contact.formFields.message")}
              rules={[REQUIRE_RULE]}
            >
              <Input.TextArea style={{ height: 180, resize: "none" }} />
            </BaseForm.Item>
          </Col>
        </Row>

        <S.SubmitButtonContainer>
          <Button
            loading={isMessageLoading}
            type="primary"
            htmlType="submit"
            block
          >
            {t("common.submit")}
          </Button>
        </S.SubmitButtonContainer>
      </BaseForm>
    </S.ContactFormContainer>
  );
};

export default ContactForm;
