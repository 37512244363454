import { FONT_SIZE, media } from "@common/constants";
import { Row, Typography } from "antd";
import styled from "styled-components";

export const Container = styled(Row)`
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const LogoImageContainer = styled.div`
  width: 60px;
  margin-right: 0.5rem;
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-size: ${FONT_SIZE.md};

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.lg};
    }
  }
`;
