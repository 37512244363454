import styled from 'styled-components';

interface IconWrapperProps {
  $isOnlyTitle: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  font-size: ${(props) => (props.$isOnlyTitle ? '2rem' : '3rem')};
  line-height: 2rem;
`;

export const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;
