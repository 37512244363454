import styled from "styled-components";
import { Content } from "antd/lib/layout/layout";

export const MainContentWrapper = styled(Content)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-overflow-style: auto; /* Internet Explorer 10+ */
  
  scrollbar-width: auto;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar  {
    display: auto;
  }
`;
