import { Col } from "antd";
import styled, { css } from "styled-components";
import { BurgerIcon } from "../Burger/Burger";

export const IconWrapper = styled(Col)`
  display: flex;
  z-index: 7;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--secondary-color);
`;
