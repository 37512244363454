import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LanguageType } from "@common/types";
import { getLanguage, persistLanguage } from "@services/LocalStorageService";
import { getBrowserLanguage } from "@utils/utils";
import i18n from "@app/i18n";

const localLanguage = getLanguage();
const browserLanguage = getBrowserLanguage();

export const useLanguage = (): {
  language: LanguageType;
  setLanguage: (locale: LanguageType) => Promise<void>;
} => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    async (language: LanguageType) => {
      persistLanguage(language);
      await i18n.changeLanguage(language);
    },
    [i18n]
  );

  useEffect(() => {
    if (localLanguage) {
      handleChangeLanguage(localLanguage);
    } else if (browserLanguage) {
      handleChangeLanguage(browserLanguage);
    }
  }, [handleChangeLanguage]);

  return useMemo(
    () => ({
      language: i18n.language as LanguageType,
      setLanguage: handleChangeLanguage,
    }),
    [handleChangeLanguage, i18n.language]
  );
};
