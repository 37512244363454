import React from "react";
import * as CommonStyles from "@styles/CommonStyles.style";
import { SubscriptionAgreement } from "../components/SubscriptionAgreement";

const SubscriptionAgreementPage: React.FC = () => {
  return (
    <CommonStyles.WrapperRow>
      <SubscriptionAgreement />
    </CommonStyles.WrapperRow>
  );
};

export default SubscriptionAgreementPage;
