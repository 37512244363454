import React, { useEffect, useCallback } from "react";
import { ConfigProvider } from "antd";
import trTR from "antd/lib/locale/tr_TR";
import enUS from "antd/lib/locale/en_US";
import AppRouter from "./routes/AppRouter";
import { fetchLicenses } from "./store/slices/Licence/thunks";
import { RequestStatus } from "@common/enums";
import { useAppDispatch, useAppSelector } from "@common/hooks/reduxHooks";
import { LanguageType } from "@common/types";
import { themeObject } from "@styles/themes/ThemeVariables";
import GlobalStyle from "@styles/themes/GlobalStyle";
import "typeface-lato";
import "typeface-montserrat";
import { useLanguage } from "./common/hooks/useLanguage";
import { fetchUserLocationInfo } from "./store/slices/UserAgent/thunks";

const App = () => {
  const { language } = useLanguage();
  const dispatch = useAppDispatch();

  const licenses = useAppSelector((state) => state.license.data);
  const licensesStatus = useAppSelector((state) => state.license.status);

  const userAgent = useAppSelector((state) => state.userAgent.data);
  const userAgentStatus = useAppSelector((state) => state.userAgent.status);

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }

    if (userAgentStatus === RequestStatus.IDLE) {
      dispatch(fetchUserLocationInfo());
    }
  }, []);

  const getLocale = useCallback((language: LanguageType) => {
    switch (language) {
      case "tr":
        return trTR;
      case "en":
      default:
        return enUS;
    }
  }, []);

  return (
    <>
      <meta name="theme-color" content={themeObject["light"].primaryColor} />
      <GlobalStyle />
      <ConfigProvider locale={getLocale(language)}>
        <AppRouter />
      </ConfigProvider>
    </>
  );
};

export default App;
