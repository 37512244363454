import React from "react";
import { Col, Divider, Radio, RadioChangeEvent, Row, Space } from "antd";
import { LanguageType } from "@common/types";
import { useLanguage } from "@common/hooks/useLanguage";
import ReactCountryFlag from "react-country-flag";
import * as S from "./LanguagePicker.style";
import { useTranslation } from "react-i18next";

interface ILanguagePicker {
  onAfterClickItem?: () => void;
}

const LanguagePicker: React.FC<ILanguagePicker> = ({ onAfterClickItem }) => {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();

  const onLanguageChange = (language: LanguageType) => {
    setLanguage(language);

    if (onAfterClickItem) {
      onAfterClickItem();
    }
  };

  return (
    <Row justify="center" align="middle">
      <S.FlagWrapper onClick={() => onLanguageChange("en")}>
        <ReactCountryFlag svg countryCode="GB" />
      </S.FlagWrapper>

      <Row justify={"center"} align={"middle"}>
        <Divider type="vertical" />
      </Row>

      <S.FlagWrapper onClick={() => onLanguageChange("tr")}>
        <ReactCountryFlag svg countryCode="TR" />
      </S.FlagWrapper>
    </Row>
  );
};

export default LanguagePicker;
