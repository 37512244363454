import styled from "styled-components";
import { Row } from "antd";
import { media } from "@common/constants";

export const ContactFormContainer = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;

  @media only screen and ${media.lg} {
    padding: 1rem 5rem;
  }

  @media only screen and ${media.xl} {
    padding: 1rem 10rem;
  }
`;

export const SubmitButtonContainer = styled(Row)`
  width: 100%;
  margin: 1.125rem 0;
`;
