import React from "react";
import { notification } from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  StopFilled,
} from "@ant-design/icons";
import { ArgsProps } from "antd/lib/notification";
import * as S from "./NotificationController.style";

type NotificationProps = ArgsProps;

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    icon: (
      <S.IconWrapper $isOnlyTitle={!config.description}>
        <CheckCircleFilled className="success-icon" />
      </S.IconWrapper>
    ),
    getContainer: () => document.getElementById("root") as HTMLElement,
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <S.EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    icon: (
      <S.IconWrapper $isOnlyTitle={!config.description}>
        <InfoCircleFilled className="info-icon" />
      </S.IconWrapper>
    ),
    getContainer: () => document.getElementById("root") as HTMLElement,
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <S.EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    icon: (
      <S.IconWrapper $isOnlyTitle={!config.description}>
        <ExclamationCircleFilled className="warning-icon" />
      </S.IconWrapper>
    ),
    getContainer: () => document.getElementById("root") as HTMLElement,
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <S.EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    icon: (
      <S.IconWrapper $isOnlyTitle={!config.description}>
        <StopFilled className="error-icon" />
      </S.IconWrapper>
    ),
    getContainer: () => document.getElementById("root") as HTMLElement,
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <S.EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

export const NotificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};
