import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { MainHeader, MainContent, MainFooter } from "@components/layout";
import * as S from "./MainLayout.style";
import { Header } from "@app/components/Header";
import { MainSider } from "../MainSider";
import { useResponsive } from "@app/common/hooks/useResponsive";

const MainLayout: React.FC = () => {
  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(true);
  const { tabletOnly, mobileOnly } = useResponsive();
  const toggleSider = () => setSiderCollapsed((prev) => !prev);

  return (
    <S.LayoutMaster>
      <S.LayoutMain>
        <MainHeader>
          <Header siderCollapsed={siderCollapsed} toggleSider={toggleSider} />
        </MainHeader>
        <MainContent>
          <React.Fragment>
            <Outlet />
            <MainFooter />
          </React.Fragment>
        </MainContent>
      </S.LayoutMain>
      {(tabletOnly || mobileOnly) && (
        <MainSider isCollapsed={siderCollapsed} toggleSider={toggleSider} />
      )}
    </S.LayoutMaster>
  );
};

export default MainLayout;
