import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const IP_ENDPOINT = "https://geolocation-db.com/json/";

export const fetchUserLocationInfo = createAsyncThunk(
  "getUserAgentInfo",
  async () => {
    const response = await axios.post(IP_ENDPOINT);
    return response.data;
  }
);
