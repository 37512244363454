import { BORDER_RADIUS, FONT_SIZE } from "@app/common/constants";
import { Row, Typography } from "antd";
import styled from "styled-components";
import { Card } from "../Card";

export const ScreenShotContainer = styled(Card)`
  
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & .ant-card-body {
    padding: 0;
  }
  box-shadow: rgba(1, 80, 154, 0.15) 10px 10px 10px;
`;

export const ScreenShot = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${BORDER_RADIUS};
  margin: 0;
  padding: 0;
`;

export const FeatureContainer = styled.div`
  padding: 2rem;
`;
