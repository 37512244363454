import React, { useEffect, useState } from "react";
import * as S from "./MainHeader.style";

interface IMainHeaderProps {
  children: React.ReactNode | React.ReactNode[];
}

const MainHeader: React.FC<IMainHeaderProps> = ({ children }) => {
  const [isBottomShadowed, setIsBottomShadowed] = useState<boolean>(false);

  const adjustBottomShadow = (e: any) => {
    e.target.scrollTop >= 10
      ? setIsBottomShadowed(true)
      : setIsBottomShadowed(false);
  };

  useEffect(() => {
    document
      .getElementById("main-content")
      ?.addEventListener("scroll", adjustBottomShadow);

    return () => {
      document
        .getElementById("main-content")
        ?.removeEventListener("scroll", adjustBottomShadow);
    };
  });

  return <S.GenericHeader $isBottomShadowed={isBottomShadowed}>{children}</S.GenericHeader>;
};

export default MainHeader;
