import styled from "styled-components";
import { Typography } from "antd";
import { FONT_SIZE } from "@common/constants";

export const Link = styled.a`
  text-decoration: none;
  color: black;
  opacity: 0.6;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: black;
  }
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-size: ${FONT_SIZE.md};
  }
`;

export const Text = styled(Typography.Text)`
  opacity: 0.6;
  font-weight: 600;
`;
