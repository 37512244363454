import styled, { css } from "styled-components";
import { Row } from "antd";
import { FONT_SIZE } from "@app/common/constants";

interface ICustomContainerProps {
  $margin: string;
}

export const CustomContainer = styled.div<ICustomContainerProps>`
  ${({ $margin }) => css`
    margin: ${$margin};
  `}
`;

export const BulletPoint = styled.span`
  color: var(--text-extra-light-color);
  margin-right: 0.25rem;
`;
