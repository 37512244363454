import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "@pages/MainPage/MainPage";
import { MainLayout } from "@app/components/layout";
import { PrivacyPolicyPage } from "@app/pages/PrivacyPolicyPage";
import { SubscriptionAgreementPage } from "@app/pages/SubscriptionAgreementPage";

const ROOT_PATH = "/";
const CONTRACT_PATH = "contracts/";

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_PATH} element={<MainLayout />}>
          <Route index element={<MainPage />} />
        </Route>
        <Route path={CONTRACT_PATH} element={<MainLayout />}>
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="subscription-agreement"
            element={<SubscriptionAgreementPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
