import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { License } from "@app/common/types";

const LICENSE_PATH = `${process.env.REACT_APP_BASE_PATH}/license/`;

export const fetchLicenses = createAsyncThunk(
  "licenses/fetchLicences",
  async () => {
    const response = await axios.get<License[]>(LICENSE_PATH);
    return response.data;
  }
);
