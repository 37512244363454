export enum Direction {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum RequestStatus {
  IDLE = "IDLE",
  PENDING = "PENDING",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED",
}

export enum LicenseType {
  LICENSE_FREE = "LICENSE_FREE",
  LICENSE_STARTER = "LICENSE_STARTER",
  LICENSE_PRO = "LICENSE_PRO",
  LICENSE_ENTERPRISE = "LICENSE_ENTERPRISE",
}

export enum Currency {
  TRY = "TRY",
  EUR = "EUR",
  USD = "USD",
}
