import { CountryInfoType } from "@common/types";

interface ICountryInfoRecord {
  [key: string]: CountryInfoType;
}
export const CountryInfo: ICountryInfoRecord = {
  AF: { countryCode: "AF", countryName: "Afghanistan", currencyCode: "AFN" },
  AX: { countryCode: "AX", countryName: "ÅLand Islands", currencyCode: "EUR" },
  AL: { countryCode: "AL", countryName: "Albania", currencyCode: "ALL" },
  DZ: { countryCode: "DZ", countryName: "Algeria", currencyCode: "DZD" },
  AS: { countryCode: "AS", countryName: "American Samoa", currencyCode: "USD" },
  AD: { countryCode: "AD", countryName: "Andorra", currencyCode: "EUR" },
  AO: { countryCode: "AO", countryName: "Angola", currencyCode: "AOA" },
  AI: { countryCode: "AI", countryName: "Anguilla", currencyCode: "XCD" },
  AQ: { countryCode: "AQ", countryName: "Antarctica", currencyCode: "USD" },
  AG: {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    currencyCode: "XCD",
  },
  AR: { countryCode: "AR", countryName: "Argentina", currencyCode: "ARS" },
  AM: { countryCode: "AM", countryName: "Armenia", currencyCode: "AMD" },
  AW: { countryCode: "AW", countryName: "Aruba", currencyCode: "AWG" },
  AC: {
    countryCode: "AC",
    countryName: "Ascension Island",
    currencyCode: "SHP",
  },
  AU: { countryCode: "AU", countryName: "Australia", currencyCode: "AUD" },
  AT: { countryCode: "AT", countryName: "Austria", currencyCode: "EUR" },
  AZ: { countryCode: "AZ", countryName: "Azerbaijan", currencyCode: "AZN" },
  BS: { countryCode: "BS", countryName: "Bahamas", currencyCode: "BSD" },
  BH: { countryCode: "BH", countryName: "Bahrain", currencyCode: "BHD" },
  BD: { countryCode: "BD", countryName: "Bangladesh", currencyCode: "BDT" },
  BB: { countryCode: "BB", countryName: "Barbados", currencyCode: "BBD" },
  BY: { countryCode: "BY", countryName: "Belarus", currencyCode: "BYN" },
  BE: { countryCode: "BE", countryName: "Belgium", currencyCode: "EUR" },
  BZ: { countryCode: "BZ", countryName: "Belize", currencyCode: "BZD" },
  BJ: { countryCode: "BJ", countryName: "Benin", currencyCode: "XOF" },
  BM: { countryCode: "BM", countryName: "Bermuda", currencyCode: "BMD" },
  BT: { countryCode: "BT", countryName: "Bhutan", currencyCode: "INR" },
  BO: { countryCode: "BO", countryName: "Bolivia", currencyCode: "BOB" },
  BA: {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    currencyCode: "BAM",
  },
  BW: { countryCode: "BW", countryName: "Botswana", currencyCode: "BWP" },
  BV: { countryCode: "BV", countryName: "Bouvet Island", currencyCode: "NOK" },
  BR: { countryCode: "BR", countryName: "Brazil", currencyCode: "BRL" },
  IO: {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    currencyCode: "USD",
  },
  VG: {
    countryCode: "VG",
    countryName: "British Virgin Islands",
    currencyCode: "USD",
  },
  BN: { countryCode: "BN", countryName: "Brunei", currencyCode: "BND" },
  BG: { countryCode: "BG", countryName: "Bulgaria", currencyCode: "BGN" },
  BF: { countryCode: "BF", countryName: "Burkina Faso", currencyCode: "XOF" },
  BI: { countryCode: "BI", countryName: "Burundi", currencyCode: "BIF" },
  KH: { countryCode: "KH", countryName: "Cambodia", currencyCode: "KHR" },
  CM: { countryCode: "CM", countryName: "Cameroon", currencyCode: "XAF" },
  CA: { countryCode: "CA", countryName: "Canada", currencyCode: "CAD" },
  IC: { countryCode: "IC", countryName: "Canary Islands", currencyCode: "EUR" },
  CV: { countryCode: "CV", countryName: "Cape Verde", currencyCode: "CVE" },
  KY: { countryCode: "KY", countryName: "Cayman Islands", currencyCode: "KYD" },
  CF: {
    countryCode: "CF",
    countryName: "Central African Republic",
    currencyCode: "XAF",
  },
  TD: { countryCode: "TD", countryName: "Chad", currencyCode: "XAF" },
  CL: { countryCode: "CL", countryName: "Chile", currencyCode: "CLP" },
  CN: { countryCode: "CN", countryName: "China", currencyCode: "CNY" },
  CX: {
    countryCode: "CX",
    countryName: "Christmas Island",
    currencyCode: "AUD",
  },
  CC: {
    countryCode: "CC",
    countryName: "Clipperton Island",
    currencyCode: "AUD",
  },
  CO: { countryCode: "CO", countryName: "Colombia", currencyCode: "COP" },
  KM: { countryCode: "KM", countryName: "Comoros", currencyCode: "KMF" },
  CG: {
    countryCode: "CG",
    countryName: "Congo - Brazzaville",
    currencyCode: "XAF",
  },
  CD: {
    countryCode: "CD",
    countryName: "Congo - Kinshasa",
    currencyCode: "CDF",
  },
  CK: { countryCode: "CK", countryName: "Cook Islands", currencyCode: "NZD" },
  CR: { countryCode: "CR", countryName: "Costa Rica", currencyCode: "CRC" },
  CI: {
    countryCode: "CI",
    countryName: "C\u00f4te d\u2019Ivoire",
    currencyCode: "XOF",
  },
  HR: { countryCode: "HR", countryName: "Croatia", currencyCode: "HRK" },
  CU: { countryCode: "CU", countryName: "Cuba", currencyCode: "CUP" },
  CW: { countryCode: "CW", countryName: "Curacao", currencyCode: "ANG" },
  CY: { countryCode: "CY", countryName: "Cyprus", currencyCode: "EUR" },
  CZ: { countryCode: "CZ", countryName: "Czech Republic", currencyCode: "CZK" },
  DK: { countryCode: "DK", countryName: "Denmark", currencyCode: "DKK" },
  DJ: { countryCode: "DJ", countryName: "Djibouti", currencyCode: "DJF" },
  DM: { countryCode: "DM", countryName: "Dominica", currencyCode: "XCD" },
  DO: {
    countryCode: "DO",
    countryName: "Dominican Republic",
    currencyCode: "DOP",
  },
  TL: { countryCode: "TL", countryName: "East Timor", currencyCode: "IDR" },
  EC: { countryCode: "EC", countryName: "Ecuador", currencyCode: "USD" },
  EG: { countryCode: "EG", countryName: "Egypt", currencyCode: "EGP" },
  SV: { countryCode: "SV", countryName: "El Salvador", currencyCode: "SVC" },
  GQ: {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    currencyCode: "XAF",
  },
  ER: { countryCode: "ER", countryName: "Eritrea", currencyCode: "ERN" },
  EE: { countryCode: "EE", countryName: "Estonia", currencyCode: "EUR" },
  ET: { countryCode: "ET", countryName: "Ethiopia", currencyCode: "ETB" },
  FK: {
    countryCode: "FK",
    countryName: "Falkland Islands",
    currencyCode: "FKP",
  },
  FO: { countryCode: "FO", countryName: "Faroe Islands", currencyCode: "DKK" },
  FJ: { countryCode: "FJ", countryName: "Fiji", currencyCode: "FJD" },
  FI: { countryCode: "FI", countryName: "Finland", currencyCode: "EUR" },
  FR: { countryCode: "FR", countryName: "France", currencyCode: "EUR" },
  GF: { countryCode: "GF", countryName: "French Guiana", currencyCode: "EUR" },
  PF: {
    countryCode: "PF",
    countryName: "French Polynesia",
    currencyCode: "XPF",
  },
  TF: {
    countryCode: "TF",
    countryName: "French Southern Territories",
    currencyCode: "EUR",
  },
  GA: { countryCode: "GA", countryName: "Gabon", currencyCode: "XAF" },
  GM: { countryCode: "GM", countryName: "Gambia", currencyCode: "GMD" },
  GE: { countryCode: "GE", countryName: "Georgia", currencyCode: "GEL" },
  DE: { countryCode: "DE", countryName: "Germany", currencyCode: "EUR" },
  GH: { countryCode: "GH", countryName: "Ghana", currencyCode: "GHS" },
  GI: { countryCode: "GI", countryName: "Gibraltar", currencyCode: "GIP" },
  GR: { countryCode: "GR", countryName: "Greece", currencyCode: "EUR" },
  GL: { countryCode: "GL", countryName: "Greenland", currencyCode: "DKK" },
  GD: { countryCode: "GD", countryName: "Grenada", currencyCode: "XCD" },
  GP: { countryCode: "GP", countryName: "Guadeloupe", currencyCode: "EUR" },
  GU: { countryCode: "GU", countryName: "Guam", currencyCode: "USD" },
  GT: { countryCode: "GT", countryName: "Guatemala", currencyCode: "GTQ" },
  GG: { countryCode: "GG", countryName: "Guernsey", currencyCode: "GBP" },
  GN: { countryCode: "GN", countryName: "Guinea", currencyCode: "GNF" },
  GW: { countryCode: "GW", countryName: "Guinea-Bissau", currencyCode: "XOF" },
  GY: { countryCode: "GY", countryName: "Guyana", currencyCode: "GYD" },
  HT: { countryCode: "HT", countryName: "Haiti", currencyCode: "HTG" },
  HM: {
    countryCode: "HM",
    countryName: "Heard Island and McDonald Islands",
    currencyCode: "AUD",
  },
  HN: { countryCode: "HN", countryName: "Honduras", currencyCode: "HNL" },
  HK: { countryCode: "HK", countryName: "Hong Kong", currencyCode: "HKD" },
  HU: { countryCode: "HU", countryName: "Hungary", currencyCode: "HUF" },
  IS: { countryCode: "IS", countryName: "Iceland", currencyCode: "ISK" },
  IN: { countryCode: "IN", countryName: "India", currencyCode: "INR" },
  ID: { countryCode: "ID", countryName: "Indonesia", currencyCode: "IDR" },
  IR: { countryCode: "IR", countryName: "Iran", currencyCode: "IRR" },
  IQ: { countryCode: "IQ", countryName: "Iraq", currencyCode: "IQD" },
  IE: { countryCode: "IE", countryName: "Ireland", currencyCode: "EUR" },
  IM: { countryCode: "IM", countryName: "Isle Of Man", currencyCode: "GBP" },
  IL: { countryCode: "IL", countryName: "Israel", currencyCode: "ILS" },
  IT: { countryCode: "IT", countryName: "Italy", currencyCode: "EUR" },
  JM: { countryCode: "JM", countryName: "Jamaica", currencyCode: "JMD" },
  JP: { countryCode: "JP", countryName: "Japan", currencyCode: "JPY" },
  JE: { countryCode: "JE", countryName: "Jersey", currencyCode: "GBP" },
  JO: { countryCode: "JO", countryName: "Jordan", currencyCode: "JOD" },
  KZ: { countryCode: "KZ", countryName: "Kazakhstan", currencyCode: "KZT" },
  KE: { countryCode: "KE", countryName: "Kenya", currencyCode: "KES" },
  KI: { countryCode: "KI", countryName: "Kiribati", currencyCode: "AUD" },
  XK: { countryCode: "XK", countryName: "Kosovo", currencyCode: "EUR" },
  KW: { countryCode: "KW", countryName: "Kuwait", currencyCode: "KWD" },
  KG: { countryCode: "KG", countryName: "Kyrgyzstan", currencyCode: "KGS" },
  LA: { countryCode: "LA", countryName: "Laos", currencyCode: "LAK" },
  LV: { countryCode: "LV", countryName: "Latvia", currencyCode: "EUR" },
  LB: { countryCode: "LB", countryName: "Lebanon", currencyCode: "LBP" },
  LS: { countryCode: "LS", countryName: "Lesotho", currencyCode: "LSL" },
  LR: { countryCode: "LR", countryName: "Liberia", currencyCode: "LRD" },
  LY: { countryCode: "LY", countryName: "Libya", currencyCode: "LYD" },
  LI: { countryCode: "LI", countryName: "Liechtenstein", currencyCode: "CHF" },
  LT: { countryCode: "LT", countryName: "Lithuania", currencyCode: "EUR" },
  LU: { countryCode: "LU", countryName: "Luxembourg", currencyCode: "EUR" },
  MO: { countryCode: "MO", countryName: "Macau", currencyCode: "MOP" },
  MK: { countryCode: "MK", countryName: "Macedonia", currencyCode: "MKD" },
  MG: { countryCode: "MG", countryName: "Madagascar", currencyCode: "MGA" },
  MW: { countryCode: "MW", countryName: "Malawi", currencyCode: "MWK" },
  MY: { countryCode: "MY", countryName: "Malaysia", currencyCode: "MYR" },
  MV: { countryCode: "MV", countryName: "Maldives", currencyCode: "MVR" },
  ML: { countryCode: "ML", countryName: "Mali", currencyCode: "XOF" },
  MT: { countryCode: "MT", countryName: "Malta", currencyCode: "EUR" },
  MH: {
    countryCode: "MH",
    countryName: "Marshall Islands",
    currencyCode: "USD",
  },
  MQ: { countryCode: "MQ", countryName: "Martinique", currencyCode: "EUR" },
  MR: { countryCode: "MR", countryName: "Mauritania", currencyCode: "MRU" },
  MU: { countryCode: "MU", countryName: "Mauritius", currencyCode: "MUR" },
  YT: { countryCode: "YT", countryName: "Mayotte", currencyCode: "EUR" },
  MX: { countryCode: "MX", countryName: "Mexico", currencyCode: "MXN" },
  FM: { countryCode: "FM", countryName: "Micronesia", currencyCode: "USD" },
  MD: { countryCode: "MD", countryName: "Moldova", currencyCode: "MDL" },
  MC: { countryCode: "MC", countryName: "Monaco", currencyCode: "EUR" },
  MN: { countryCode: "MN", countryName: "Mongolia", currencyCode: "MNT" },
  ME: { countryCode: "ME", countryName: "Montenegro", currencyCode: "EUR" },
  MS: { countryCode: "MS", countryName: "Montserrat", currencyCode: "XCD" },
  MA: { countryCode: "MA", countryName: "Morocco", currencyCode: "MAD" },
  MZ: { countryCode: "MZ", countryName: "Mozambique", currencyCode: "MZN" },
  MM: { countryCode: "MM", countryName: "Myanmar", currencyCode: "MMK" },
  NA: { countryCode: "NA", countryName: "Namibia", currencyCode: "NAD" },
  NR: { countryCode: "NR", countryName: "Nauru", currencyCode: "AUD" },
  NP: { countryCode: "NP", countryName: "Nepal", currencyCode: "NPR" },
  NL: { countryCode: "NL", countryName: "Netherlands", currencyCode: "EUR" },
  AN: {
    countryCode: "AN",
    countryName: "Netherlands Antilles",
    currencyCode: "ANG",
  },
  NC: { countryCode: "NC", countryName: "New Caledonia", currencyCode: "XPF" },
  NZ: { countryCode: "NZ", countryName: "New Zealand", currencyCode: "NZD" },
  NI: { countryCode: "NI", countryName: "Nicaragua", currencyCode: "NIO" },
  NE: { countryCode: "NE", countryName: "Niger", currencyCode: "XOF" },
  NG: { countryCode: "NG", countryName: "Nigeria", currencyCode: "NGN" },
  NU: { countryCode: "NU", countryName: "Niue", currencyCode: "NZD" },
  NF: { countryCode: "NF", countryName: "Norfolk Island", currencyCode: "AUD" },
  KP: { countryCode: "KP", countryName: "North Korea", currencyCode: "KPW" },
  MP: {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    currencyCode: "USD",
  },
  NO: { countryCode: "NO", countryName: "Norway", currencyCode: "NOK" },
  OM: { countryCode: "OM", countryName: "Oman", currencyCode: "OMR" },
  PK: { countryCode: "PK", countryName: "Pakistan", currencyCode: "PKR" },
  PW: { countryCode: "PW", countryName: "Palau", currencyCode: "USD" },
  PS: { countryCode: "PS", countryName: "Palestine", currencyCode: "JOD" },
  PA: { countryCode: "PA", countryName: "Panama", currencyCode: "PAB" },
  PG: {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    currencyCode: "PGK",
  },
  PY: { countryCode: "PY", countryName: "Paraguay", currencyCode: "PYG" },
  PE: { countryCode: "PE", countryName: "Peru", currencyCode: "PEN" },
  PH: { countryCode: "PH", countryName: "Philippines", currencyCode: "PHP" },
  PN: { countryCode: "PN", countryName: "Pitcairn", currencyCode: "NZD" },
  PL: { countryCode: "PL", countryName: "Poland", currencyCode: "PLN" },
  PT: { countryCode: "PT", countryName: "Portugal", currencyCode: "EUR" },
  PR: { countryCode: "PR", countryName: "Puerto Rico", currencyCode: "USD" },
  QA: { countryCode: "QA", countryName: "Qatar", currencyCode: "QAR" },
  RE: { countryCode: "RE", countryName: "Reunion", currencyCode: "EUR" },
  RO: { countryCode: "RO", countryName: "Romania", currencyCode: "RON" },
  RU: { countryCode: "RU", countryName: "Russia", currencyCode: "RUB" },
  RW: { countryCode: "RW", countryName: "Rwanda", currencyCode: "RWF" },
  BL: {
    countryCode: "BL",
    countryName: "Saint Barth\u00c9Lemy",
    currencyCode: "EUR",
  },
  SH: { countryCode: "SH", countryName: "Saint Helena", currencyCode: "GBP" },
  KN: {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    currencyCode: "XCD",
  },
  LC: { countryCode: "LC", countryName: "Saint Lucia", currencyCode: "XCD" },
  MF: {
    countryCode: "MF",
    countryName: "Saint Martin (French Part)",
    currencyCode: "EUR",
  },
  PM: {
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
  },
  VC: {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
  },

  WS: { countryCode: "WS", countryName: "Samoa", currencyCode: "WST" },
  SM: { countryCode: "SM", countryName: "San Marino", currencyCode: "EUR" },
  ST: {
    countryCode: "ST",
    countryName: "Sao Tome and Principe",
    currencyCode: "STN",
  },
  SA: { countryCode: "SA", countryName: "Saudi Arabia", currencyCode: "SAR" },
  SN: { countryCode: "SN", countryName: "Senegal", currencyCode: "XOF" },
  RS: { countryCode: "RS", countryName: "Serbia", currencyCode: "RSD" },
  CS: {
    countryCode: "CS",
    countryName: "Serbia and Montenegro",
    currencyCode: "RSD",
  },
  SC: { countryCode: "SC", countryName: "Seychelles", currencyCode: "SCR" },
  SL: { countryCode: "SL", countryName: "Sierra Leone", currencyCode: "SLL" },
  SG: { countryCode: "SG", countryName: "Singapore", currencyCode: "SGD" },
  SX: {
    countryCode: "SX",
    countryName: "Sint Maarten (Dutch Part)",
    currencyCode: "ANG",
  },
  SK: { countryCode: "SK", countryName: "Slovakia", currencyCode: "EUR" },
  SI: { countryCode: "SI", countryName: "Slovenia", currencyCode: "EUR" },
  SB: {
    countryCode: "SB",
    countryName: "Solomon Islands",
    currencyCode: "SBD",
  },
  SO: { countryCode: "SO", countryName: "Somalia", currencyCode: "SOS" },
  ZA: { countryCode: "ZA", countryName: "South Africa", currencyCode: "ZAR" },

  GS: {
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    currencyCode: "GBP",
  },
  KR: { countryCode: "KR", countryName: "South Korea", currencyCode: "KRW" },
  SS: { countryCode: "SS", countryName: "South Sudan", currencyCode: "SSP" },
  ES: { countryCode: "ES", countryName: "Spain", currencyCode: "EUR" },
  LK: { countryCode: "LK", countryName: "Sri Lanka", currencyCode: "LKR" },
  SD: { countryCode: "SD", countryName: "Sudan", currencyCode: "SDG" },
  SR: { countryCode: "SR", countryName: "Suriname", currencyCode: "SRD" },
  SJ: {
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
  },
  SZ: { countryCode: "SZ", countryName: "Swaziland", currencyCode: "SZL" },
  SE: { countryCode: "SE", countryName: "Sweden", currencyCode: "SEK" },
  CH: { countryCode: "CH", countryName: "Switzerland", currencyCode: "CHF" },
  SY: { countryCode: "SY", countryName: "Syria", currencyCode: "SYP" },
  TW: { countryCode: "TW", countryName: "Taiwan", currencyCode: "TWD" },
  TJ: { countryCode: "TJ", countryName: "Tajikistan", currencyCode: "TJS" },
  TZ: { countryCode: "TZ", countryName: "Tanzania", currencyCode: "TZS" },
  TH: { countryCode: "TH", countryName: "Thailand", currencyCode: "THB" },
  TG: { countryCode: "TG", countryName: "Togo", currencyCode: "XOF" },
  TK: { countryCode: "TK", countryName: "Tokelau", currencyCode: "NZD" },
  TO: { countryCode: "TO", countryName: "Tonga", currencyCode: "TOP" },
  TT: {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    currencyCode: "TTD",
  },
  TA: {
    countryCode: "TA",
    countryName: "Tristan da Cunha",
    currencyCode: "SHP",
  },
  TN: { countryCode: "TN", countryName: "Tunisia", currencyCode: "TND" },
  TR: { countryCode: "TR", countryName: "Turkey", currencyCode: "TRY" },
  TM: { countryCode: "TM", countryName: "Turkmenistan", currencyCode: "TMT" },
  TC: {
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    currencyCode: "USD",
  },
  TV: { countryCode: "TV", countryName: "Tuvalu", currencyCode: "AUD" },
  UM: {
    countryCode: "UM",
    countryName: "U.S. Outlying Islands",
    currencyCode: "USD",
  },
  VI: {
    countryCode: "VI",
    countryName: "U.S. Virgin Islands",
    currencyCode: "USD",
  },
  UG: { countryCode: "UG", countryName: "Uganda", currencyCode: "UGX" },
  UA: { countryCode: "UA", countryName: "Ukraine", currencyCode: "UAH" },
  AE: {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    currencyCode: "AED",
  },
  GB: { countryCode: "GB", countryName: "United Kingdom", currencyCode: "GBP" },
  US: { countryCode: "US", countryName: "United States", currencyCode: "USD" },
  UY: { countryCode: "UY", countryName: "Uruguay", currencyCode: "UYU" },
  UZ: { countryCode: "UZ", countryName: "Uzbekistan", currencyCode: "UZS" },
  VU: { countryCode: "VU", countryName: "Vanuatu", currencyCode: "VUV" },
  VA: { countryCode: "VA", countryName: "Vatican", currencyCode: "EUR" },
  VE: { countryCode: "VE", countryName: "Venezuela", currencyCode: "VES" },
  VN: { countryCode: "VN", countryName: "Vietnam", currencyCode: "VND" },
  WF: {
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    currencyCode: "XPF",
  },
  EH: { countryCode: "EH", countryName: "Western Sahara", currencyCode: "MAD" },
  YE: { countryCode: "YE", countryName: "Yemen", currencyCode: "YER" },
  ZM: { countryCode: "ZM", countryName: "Zambia", currencyCode: "ZMW" },
  ZW: { countryCode: "ZW", countryName: "Zimbabwe", currencyCode: "ZWL" },
};
