import React from "react";
import { Col, Row } from "antd";
import { AboutUs } from "../AboutUs";
import { FollowUs } from "../FollowUs";
import { QuickLinks } from "../QuickLinks";

import * as CommonStyles from "@styles/CommonStyles.style";
import * as S from "./Footer.style";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CommonStyles.WrapperRow>
      <CommonStyles.FullyCenteredRow>
        <Col span={24}>
          <Row gutter={[24, 24]} wrap>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <AboutUs />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <QuickLinks />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <FollowUs />
            </Col>
          </Row>

          <S.CopyRightContainer>
            <S.CopyRightText>{t("footer.copyRight")}</S.CopyRightText>
          </S.CopyRightContainer>
        </Col>
      </CommonStyles.FullyCenteredRow>
    </CommonStyles.WrapperRow>
  );
};

export default Footer;
